import Icon from '@Atom/Icon'
import LoadingAnimation from '@Atom/LoadingAnimation'
import ModalWrapper from '@Atom/ModalWrapper'
import useWindowSize from '@Hooks/useWindowSize'
import AutocompleteField from '@Molecule/AutocompleteField'
import InputField from '@Molecule/InputField'
import { getPositionsQualification, getQualificationSummary, updateQualifications } from '@Services/client/dashboard'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import RequestSuccessModal from '../RequestSuccessModal'
import Styles from './style.module.scss'
import { makeRandomString } from '@Helpers/makeRandomString'
import { EmptyPlaceholder } from '@Organism/Additional/RenewData/Third'

export default function SeeQualificationsModal({
  data,
  onClose = () => { },
  sync
}) {
  const { width } = useWindowSize()
  const [isEditMode, setIsEditMode] = useState(null)
  const [selectedRegion, setSelectedRegion] = useState(data?.regions[0])

  const [onFetching, setOnFetching] = useState(true)
  const [qualifications, setQualifications] = useState([])

  const [onSubmitting, setOnSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const fetchData = useCallback(async () => {
    try {
      setOnFetching(true)
      const { response } = await getQualificationSummary(data?.positionName, localStorage?.getItem('clientCode'))
      setQualifications(response?.qualifications?.map(obj => {
        return {
          id: obj?.id,
          qualificationID: obj?.id,
          name: obj?.title,
          value: obj?.value
        }
      }))
      setOnFetching(false)
    } catch (e) {
      setOnFetching(false)
      console.log(e, 'error on fetching')
    }
  }, [data?.positionName])


  useEffect(() => {
    fetchData()
  }, [fetchData])


  const [qualificationsForm, setQualificationsForm] = useState(qualifications)

  useEffect(() => {
    setQualificationsForm(qualifications)
  }, [qualifications])

  const handleChangeQualificationForm = (id, name, value) => {
    // setSelectedRegionForm(prev => {
    //   return {
    //     ...prev,
    //     qualifications: prev?.qualifications?.map(obj => {
    //       if (obj?.id === id) {
    //         return {
    //           ...obj,
    //           [name]: value
    //         }
    //       } return obj
    //     })
    //   }
    // })
    setQualificationsForm(qualificationsForm?.map(obj => {
      if (obj?.id === id) {
        return {
          ...obj,
          [name]: value
        }
      } return obj
    }))
  }


  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    if (selectedRegion && !isEditMode) {
      scrollingTop();
    }
  }, [isEditMode, selectedRegion]);

  const disableSubmit = useMemo(() => {
    return onFetching || onSubmitting
  }, [onFetching, onSubmitting])

  const handleSubmit = async () => {
    try {
      setOnSubmitting(true)
      const form = new URLSearchParams()
      form?.append('summaryID', selectedRegion?.id)
      for (let i = 0; i < qualificationsForm?.length; i++) {
        form?.append(`qualifications[${i}][id]`, qualificationsForm[i]?.qualificationID || '')
        form?.append(`qualifications[${i}][title]`, qualificationsForm[i]?.name)
        form?.append(`qualifications[${i}][value]`, qualificationsForm[i]?.value)
      }
      await updateQualifications(form)
      fetchData()
      setOnSubmitting(false)
      setIsSuccess(true)
    } catch (err) {
      console.log(err)
      setOnSubmitting(false)
    }
  }

  if (isSuccess) {
    return (
      <RequestSuccessModal
        show={isSuccess}
        title='Update Kualifikasi Berhasil'
        description='Update kualifikasi telah berhasil di simpan.'
        closeButtonText='Tutup'
        onClose={() => {
          setIsSuccess(false)
          setIsEditMode(false)
        }}
      />
    )
  }

  return (
    <ModalWrapper show={!!data} handleClose={onClose}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h3>Daftar Kualifikasi - {data?.positionName}</h3>
          {/* {
            !isEditMode
              ?
              <div className={Styles.regionsSwitcher}>
                {data?.regions?.map((r, rI) => (
                  <div key={rI} onClick={() => setSelectedRegion(r)} className={r?.cityName === selectedRegion?.cityName ? Styles.active : ''}>
                    {r?.cityName}
                  </div>
                ))}
              </div>
              :
              <span>
                {selectedRegion?.cityName}
              </span>
          } */}
        </div>
        <div className={Styles.content}>
          {!isEditMode
            ?
            <div className={Styles.qualifications}>
              <div className={Styles.autoScroll} ref={targetElement} />
              {
                onFetching
                  ?
                  <div style={{paddingTop: '60px'}}>
                    <LoadingAnimation />
                  </div>
                  :
                  qualifications?.length
                    ?
                    qualifications?.map((q, qI) => (
                      <div key={qI} className={Styles.qualification}>
                        <Icon icon={'triangle-point'} size={14} color={'#125EB9'} />
                        <p>
                          <span>{q?.name}</span>
                          <span> : {q?.value}</span>
                        </p>
                      </div>
                    ))
                    :
                    <div className={Styles.notFoundBox}>
                      <EmptyPlaceholder title={"No Data"} desc="No Data Yet" />
                    </div>
              }
            </div>
            :
            <div className={Styles.form}>
              {
                qualificationsForm?.map((q, qI) => (
                  <div key={qI} className={Styles.fQualification}>
                    <AutocompleteField
                      title={'Kategori Kualifikasi'}
                      value={q?.name}
                      placeholder={'Kategori kualifikasi'}
                      setValue={(newVal) => handleChangeQualificationForm(q?.id, 'name', newVal)}
                      searchOptions={sync?.qualifications?.length
                        ?
                        sync?.qualifications?.map(obj => obj?.value)
                        :
                        []
                      }
                      // preventIconRotate
                      // popupIcon='search'
                      popupIconSize='20px'
                      disabled={q?.qualificationID}
                    />
                    <InputField
                      title={'Detail Kualifikasi'}
                      value={q?.value}
                      placeholder={'Detail kualifikasi'}
                      onChange={(e) => handleChangeQualificationForm(q?.id, 'value', e?.target?.value)}
                    />
                    <div>
                      <button
                        onClick={() => setQualificationsForm(qualificationsForm?.filter(obj => obj?.id !== q?.id))}
                        disabled={qualificationsForm?.length === 1 || q?.qualificationID}
                      >
                        <Icon icon={'trash'} size={width > 768 ? 24 : 20} />
                      </button>
                    </div>
                  </div>
                ))
              }
              <button
                //   onClick={() => {
                //   handleChangeDetails('cities', [...detailsForm?.cities, {
                //     id: makeRandomString(5),
                //     cityCode: '',
                //     cityName: '',
                //     quota: '',
                //     vacant: ''
                //   }])
                // }}
                onClick={() => setQualificationsForm([...qualificationsForm, {
                  id: makeRandomString(5),
                  qualificationID: '',
                  name: '',
                  value: ''
                }])}
              >
                <span>Tambah Kualifikasi</span>
                <Icon icon={'page-plus'} size={20} />
              </button>
            </div>
          }
        </div>
        <div style={{display: 'none'}} className={Styles.actions}>
          {isEditMode
            &&
            <button className={Styles.cancel} onClick={() => setIsEditMode(false)}>
              <span>
                Batal
              </span>
            </button>
          }
          <button disabled={disableSubmit} onClick={!isEditMode ? () => setIsEditMode(selectedRegion) : handleSubmit}>
            {!isEditMode
              &&
              <Icon icon={'edit'} size={20} />

            }
            <span>{!isEditMode ? 'Edit' : (onSubmitting ? 'Menyimpan...' : 'Simpan')}</span>
          </button>
        </div>
      </div>
    </ModalWrapper>
  )
}